import React from 'react'
import './AlternatingContent.scss'
import Image from '../Image'

const AlternatingContent = props => {
  const getTitle = item => {
    return item.titleDangerous ? (
      <div
        className="item-title"
        dangerouslySetInnerHTML={{ __html: item.title }}
      />
    ) : (
      <div className="item-title">{item.title}</div>
    )
  }

  const getDescription = item => {
    return item.descriptionDangerous ? (
      <div
        className="item-description"
        dangerouslySetInnerHTML={{ __html: item.description }}
      />
    ) : (
      <div className="item-description">{item.description}</div>
    )
  }

  const items = props.items.map((item, index) => {
    return (
      <div className={`item item-${index % 2 === 0 ? 'left' : 'right'}`} key={index}>
        <div className="item-image squareImage">
          <Image fluid={{ ...item.image, aspectRatio: 1 }} alt={item.title} />
        </div>
        <div className="item-content">
          {getTitle(item)}
          {getDescription(item)}
        </div>
      </div>
    )
  })

  return (
    <div className="AlternatingContent">
      {items}
      <div className="item" />
    </div>
  )
}

export default AlternatingContent
