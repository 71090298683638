import React from 'react'
import './Team.scss'
import HeroBanner from '../../components/HeroBanner'
import AlternatingContent from '../../components/AlternatingContent'
import Layout from '../../components/Layout'
import { graphql } from 'gatsby'
import { GenericWordpressToObject } from '../../util/WordpressUtil'
import { ListGroup, ListGroupItem } from 'reactstrap'

const Team = ({ data }) => {
  return (
    <Layout>
      <div className="Team">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="Meet Our Team"
          caption="OCR employs a bunch of people from different ages and walks of life, here's our current trainers"
          actionURL="#join-the-team"
          actionText="Join the Team"
        />
        <div className="container content">
          <AlternatingContent
            items={data.allWordpressWpTeamMember.edges.map(
              GenericWordpressToObject
            )}
          />

          <h2 id="join-the-team">Join the Team</h2>
          <p>
            We are looking for an awesome individual who is available evening
            and weekends, to work for us you must posses the following
            attributes:
          </p>
          <ListGroup>
            <ListGroupItem>
              Posses fitness and obstacle course knowledge
            </ListGroupItem>
            <ListGroupItem>Be computer literate</ListGroupItem>
            <ListGroupItem>
              Be a self starter (like actually, "what do I do now, or There is
              nothing to do" should not be part of your vocabulary)
            </ListGroupItem>
            <ListGroupItem>CPR Certified</ListGroupItem>
            <ListGroupItem>Be allowed to work with kids</ListGroupItem>
            <ListGroupItem>Be awesome</ListGroupItem>
            <ListGroupItem>Be available evenings and weekends</ListGroupItem>
          </ListGroup>

          <p>
            We are looking for someone who can work 24 hrs a week for kids
            class, open gym, and group parties.
          </p>

          <p>
            If you are awesome, please forward your resume to
            <a href="mailto:joshua@painistemporary.net">joshua@painistemporary.net</a> and explain why you would want to work
            for him at his gym.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Team

export const query = graphql`
  query {
    allWordpressWpTeamMember(sort: {order: ASC, fields: order}) {
      edges {
        node {
          content
          title
          featured_media {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    file(url: { eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
